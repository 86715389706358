/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#width",
    "aria-label": "width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Width"), "\n", React.createElement(_components.p, null, "Utilities for setting the width of an element."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "w={size}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "width: {size};"))))), "\n", React.createElement(_components.h2, {
    id: "auto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#auto",
    "aria-label": "auto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Auto"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "w=\"auto\""), " to let the browser calculate and select the width for the element. You can use it to unset a specific width:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div w={{ xs: 24, md: 'auto' }} />\n")), "\n", React.createElement(_components.h2, {
    id: "screen-width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#screen-width",
    "aria-label": "screen width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Screen Width"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "w=\"100vw\""), " to make an element span the entire width of the viewport."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div h={12} w=\"100vw\" />\n")), "\n", React.createElement(_components.h2, {
    id: "scaled-width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaled-width",
    "aria-label": "scaled width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaled Width"), "\n", React.createElement(_components.p, null, "All values specified in the ", React.createElement(_components.code, null, "sizes"), " theme section are automatically applied. Note there is a little difference for scales between ", React.createElement(_components.code, null, "0"), " and ", React.createElement(_components.code, null, "1"), " due to conflicting with fluid range. You have to add a suffix ", React.createElement(_components.code, null, "s"), " to target values from theme, like ", React.createElement(_components.code, null, "0.5"), " becomes ", React.createElement(_components.code, null, "0.5s"), " and ", React.createElement(_components.code, null, "1"), " becomes ", React.createElement(_components.code, null, "1s"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" flexDirection=\"column\" spaceY={4} mx=\"auto\" w={64}>\n      {['1s', 8, 12, 16, 24].map((v) => (\n        <x.div key={v} display=\"flex\" alignItems=\"center\" spaceX={4}>\n          <x.p fontSize=\"sm\" color=\"light-blue-600\" w={24} textAlign=\"right\">\n            w={v}\n          </x.p>\n          <x.div\n            h={8}\n            w={v}\n            borderRadius=\"md\"\n            bg=\"light-blue-500\"\n            color=\"white\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          />\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div>\n    <x.div w=\"1s\" />\n    <x.div w={8} />\n    <x.div w={12} />\n    <x.div w={16} />\n    <x.div w={24} />\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fixed-width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fixed-width",
    "aria-label": "fixed width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fixed Width"), "\n", React.createElement(_components.p, null, "Any valid value is accepted in width, numbers are converted to ", React.createElement(_components.code, null, "px"), ", other units have to be specified."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" flexDirection=\"column\" spaceY={4} mx=\"auto\" w={64}>\n      {[123, '12px', '4rem', '3ex'].map((v) => (\n        <x.div key={v} display=\"flex\" alignItems=\"center\" spaceX={4}>\n          <x.p fontSize=\"sm\" color=\"emerald-600\" w={24} textAlign=\"right\">\n            w={v}\n          </x.p>\n          <x.div\n            h={8}\n            w={v}\n            borderRadius=\"md\"\n            bg=\"emerald-500\"\n            color=\"white\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          />\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div>\n    <x.div w={123} />\n    <x.div w=\"12px\" />\n    <x.div w=\"4rem\" />\n    <x.div w=\"3ex\" />\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fluid-width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fluid-width",
    "aria-label": "fluid width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fluid Width"), "\n", React.createElement(_components.p, null, "Values from ", React.createElement(_components.code, null, "0"), " to ", React.createElement(_components.code, null, "1"), " are converted into percentages. As a fraction or a number, both are an expression. Of course specifying ", React.createElement(_components.code, null, "[value]%"), " is also possible."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=rose"
  }, "<>\n  <template preview>\n    <x.div spaceY={6}>\n      {[\n        [\n          ['1/2', 1 / 2],\n          ['1/2', 1 / 2],\n        ],\n        [\n          ['2/5', 2 / 5],\n          ['3/5', 3 / 5],\n        ],\n        [\n          ['0.2', 0.2],\n          ['0.8', 0.8],\n        ],\n        [\n          ['calc(50% - 40px)', 'calc(50% - 40px)'],\n          ['calc(50% + 40px)', 'calc(50% + 40px)'],\n        ],\n      ].map((v, i) => (\n        <x.div\n          key={i}\n          display=\"flex\"\n          borderRadius=\"md\"\n          overflow=\"hidden\"\n          color=\"white\"\n          fontFamily=\"mono\"\n        >\n          <x.div w={v[0][1]} px={2} py={4} bg=\"rose-400\" textAlign=\"center\">\n            w={v[0][0]}\n          </x.div>\n          <x.div w={v[1][1]} px={2} py={4} bg=\"rose-500\" textAlign=\"center\">\n            w={v[1][0]}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\">\n    <x.div w={1 / 2}>w=1/2</x.div>\n    <x.div w={1 / 2}>w=1/2</x.div>\n  </x.div>\n  <x.div display=\"flex\">\n    <x.div w={2 / 5}>w=2/5</x.div>\n    <x.div w={3 / 5}>w=3/5</x.div>\n  </x.div>\n  <x.div display=\"flex\">\n    <x.div w={0.2}>w=0.2</x.div>\n    <x.div w={0.8}>w=0.8</x.div>\n  </x.div>\n  <x.div display=\"flex\">\n    <x.div w=\"calc(50% - 40px)\">w=calc(50% - 40px)</x.div>\n    <x.div w=\"calc(50% + 40px)\">w=calc(50% + 40px)</x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the margin of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "w={{ md: 1 }}"), " to an element would apply the ", React.createElement(_components.code, null, "w={1}"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div w={{ xs: 1 / 2, md: 1 }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", React.createElement(_components.h3, {
    id: "sizes-scale",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sizes-scale",
    "aria-label": "sizes scale permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sizes scale"), "\n", React.createElement(_components.p, null, "f you'd like to customize your values for width, height, min-width, min-height, max-width, max-height, all at once, use the ", React.createElement(_components.code, null, "theme.sizes"), " section of your theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  // theme.js\n  export const theme = {\n    sizes: {\n+     sm: '8px',\n+     md: '16px',\n+     lg: '24px',\n+     xl: '48px',\n    },\n  }\n")), "\n", React.createElement(_components.p, null, "Learn more about customizing the default theme in the ", React.createElement(_components.a, {
    href: "/docs/theme/"
  }, "theme customization documentation"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
